export default function Footer({ scrollToView }) {
  return (
    <footer className="footer-admin mt-auto footer-light bg-white">
      <div className="row mx-0 p-3 w-100 custom-bg">
        <div className="col-md-12 small align-self-center text-center text-white">
          <span
            onClick={() => scrollToView("getStarted")}
            style={{ cursor: "pointer" }}
          >
            Get Started
          </span>
          <span className="ps-2 pe-2">&bull;</span>
          <span
            onClick={() => scrollToView("features")}
            style={{ cursor: "pointer" }}
          >
            Features
          </span>
          <span className="ps-2 pe-2">&bull;</span>
          <span
            onClick={() => scrollToView("whyItWorks")}
            style={{ cursor: "pointer" }}
          >
            Why it works
          </span>
        </div>
      </div>
    </footer>
  );
}
