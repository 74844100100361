import "./App.css";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./assets/css/particleStyle.css";
import useWindowDimensions from "./utils/useWindowDimensions";

import programmable from "./assets/images/programmable.png";
import WOW from "wowjs";
import immutable from "./assets/images/immutable.svg";
import iphone from "./assets/images/iphone.svg";
import thumbsUp from "./assets/images/thumbs-up.svg";
import distributed from "./assets/images/distributed.svg";
import shield from "./assets/images/shield.svg";
import unanimous from "./assets/images/unanimous.svg";
import TopNavigationBar from "./components/TopNavigationBar";
import Footer from "./components/Footer";
import GetStarted from "./components/GetStarted";
const feather = require("feather-icons");

const counts = [];
let lastScrollTop = 0;
function App() {
  const { height, width } = useWindowDimensions();
  // const width = 1024;
  const ServicesRef = useRef([]);

  const [infoCards] = useState([
    {
      image: iphone,
      title: "Programmable",
      description:
        "TutorFi blockchain is programmable, developed n ASP.NET, VB.NET, C# and MS SQL Server as Database.",
    },
    {
      image: shield,
      title: "Secure",
      description:
        "TutorFi blockchain is secured, and hosted on a dedicated Windows Server 2012 R2, with Symantec End-Point Security installed and extra layer of Firewall and server accessibility only through Open VPN.",
    },
    {
      image: distributed,
      title: "Distributed",
      description:
        "All blockchain users have copies of record i.e. each user (Expert, Manager and User) have copies of programs and its modules accessible from their own user account.",
    },
    {
      image: immutable,
      title: "Immutable",
      description:
        "Records are immutable in both blockchains and only be changed/updated by respective user from their own account.",
    },
    {
      image: thumbsUp,
      title: "Time-Stamped",
      description:
        "Each operation performed by a user is logged by date-time stamp and each transaction is stored with proper date-time.",
    },
    {
      image: unanimous,
      title: "Unanimous",
      description:
        "All blockchain users validate records of each user, e.g. in TutorFi blockchain Managers and Experts validates programs and modules of each users in order to ensure transparency in the blockchain.",
    },
  ]);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

    feather.replace();
    document.getElementById("bannerBox").style.paddingTop = `${
      document.getElementById("topNavigationBar")?.clientHeight
    }px`;
  }, []);

  useEffect(() => {
    document.body.classList.add("sidenav-toggled");
  }, [width]);

  const scrollToView = (view) => {
    window.scrollTo({
      top: ServicesRef.current[view].offsetTop - 140,
      behavior: "smooth",
    });
  };

  const scrollEventListener = useCallback((e) => {
    const interval = setInterval(() => {
      if (counts.length >= 15) {
        clearInterval(interval);
        counts.length = 0;
      }
      counts.push(0);
      document.getElementById("bannerBox").style.paddingTop = `${
        document.getElementById("topNavigationBar")?.clientHeight
      }px`;
    }, 1000);

    return () => {
      counts.length = 0;
      clearInterval(counts);
    };
  }, []);

  useLayoutEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        scrollEventListener();
      },
      true
    );
    return () => {
      window.removeEventListener("scroll", scrollEventListener);
    };
  }, [scrollEventListener]);

  return (
    <>
      <TopNavigationBar ServicesRef={ServicesRef} scrollToView={scrollToView} />
      <div id="layoutSidenav">
        <div id="layoutSidenav_content" style={{ top: 0 }}>
          <main>
            <div id="particleBox">
              <div className="position-relative">
                <div
                  id="bannerBox"
                  className="w-100 text-center position-absolute margin-auto text-white"
                  style={{
                    zIndex: 1,
                    // height: height > 768 ? 668 : height,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <h1
                    className="text-white slideInLeft mt-4 mb-0"
                    style={{ fontSize: width < 560 ? `7vw` : `4.5vw` }}
                  >
                    Welcome to TutorFi
                  </h1>
                  <p
                    style={{
                      maxWidth: "60ch",
                    }}
                    className="m-auto text-start fs-7 mt-0 p-4 mb-0"
                  >
                    TutorFi is human-verified blockchain that uses real people
                    in a checks and balances environment to confirm each block.
                    There are many different use cases compared to typical ai
                    blockchains. By allowing real people to be accountable for
                    verifying blocks of data, information processes, proof of
                    work, etc, it opens up a whole new world of possibilities.
                  </p>
                  <div
                    onClick={() => scrollToView("whyItWorks")}
                    style={{
                      maxWidth: 200,
                      color: "gold",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    className="mx-auto"
                  >
                    Scroll Down To Explore
                  </div>
                  <div
                    onClick={() => scrollToView("whyItWorks")}
                    style={{ cursor: "pointer" }}
                    className="mx-auto"
                  >
                    <div className="mouse_scroll mt-2">
                      <div className="mouse">
                        <div className="wheel"></div>
                      </div>
                      <div>
                        <span className="m_scroll_arrows unu"></span>
                        <span className="m_scroll_arrows doi"></span>
                        <span className="m_scroll_arrows trei"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="py-5"
              style={{
                background: "var(--bs-body-bg)",
              }}
              id="whyItWorks"
              ref={(element) => {
                ServicesRef.current["whyItWorks"] = element;
              }}
            >
              <div className="container text-left" style={{ padding: "4vw 0" }}>
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: width < 560 ? `7vw` : `2.5vw`,
                  }}
                >
                  Why Manually Verifying “Proof Of Work”
                  <br /> Creates New Categories Of Use Cases
                </h1>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 mt-5  fs-5 text-black`}
                >
                  When you make real managers or consultants accountable to
                  verify proof of work, the KPI (Key Performance Indicator) or
                  results that your operations and onboarding programs produce,
                  this will transform your organization in ways you never
                  thought possible.
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4  fs-5 text-black `}
                >
                  The fun begins when you title an internal onboarding or
                  operational program to deliver a specific result. What this
                  means is that the name of the program is the actual
                  result/KPI.
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto  px-4 my-4 fs-5 text-black`}
                >
                  The reason this was never done before is because there was
                  never an easy way to make people accountable. TutorFi solves
                  this problem by making everyone in your organization
                  accountable not just for the result, but accomplishing and
                  showing proof of work for each step until completion.
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4 fs-5 text-black`}
                >
                  Since the early days of business school, every entrepreneur &
                  manager knows that standard operating procedures are required
                  to run a successful business and are key for onboarding and
                  operations. Some of the greatest business books of all time
                  teach the importance of defining processes and creating a
                  handbook of SOP's for employees.
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4 fs-5 text-black`}
                >
                  For years, the biggest names in technology have tried and
                  tried again to create project management & blockchain
                  technology that could help employees complete each procedure.
                  Those same companies attempted to train their client's to not
                  only use their software, but also think like leaders and
                  define company procedures so onboarding and operations
                  training could produce results.
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4 fs-5 text-black`}
                >
                  Sadly, leaders never got a chance to rise to the occasion and
                  create meaningful training programs that produced those
                  measurable results known as KPI's (key performance
                  indicators).
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4 fs-5 text-black`}
                >
                  The reason this couldn't be done is because there was not a
                  solution out there that had real accountability built in to
                  it. Quizzes that managers don't review and videos that someone
                  supposedly watched are NOT real accountability.
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4 fs-5 text-black`}
                >
                  Real accountability requires evidence (proof of work) that
                  each procedure was completed as instructed and that evidence
                  must be reviewed by a manager every step of the way.
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4 fs-5 text-black`}
                >
                  Without accountability in place, leaders can't think like true
                  leaders. Instead, they are forced to create programs out of
                  fear that they will never have a KPI attached to them or a way
                  to measure their true success.
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4 fs-5 text-black`}
                >
                  For example, there are plenty of sales training programs
                  within companies, but none that teach sales reps to sell X
                  units per week, month or year. Why? Because the leader in
                  charge is “scared” to create a program with a KPI attached
                  without real accountability.
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4 fs-5 text-black`}
                >
                  When leaders can name their programs around the results their
                  company needs (Example: How To Sell 10 Units Per Day From
                  Incoming Calls), the entire dynamic of how that company
                  operates will forever change and they will never go back to
                  their old way of operating. This is the power of TutorFi
                  blockchain!
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4 fs-5 text-black`}
                >
                  As a result, companies who adapt to this new way of corporate
                  training can enjoy more revenue and efficiency from their
                  employees than ever before. The best part is, top level
                  managers can watch the results of each department get done
                  from anywhere. This is true freedom for everyone involved
                  including the employee who is guided to hit KPI's that matter.
                </p>
                <p
                  style={{ maxWidth: "94ch" }}
                  className={`m-auto px-4 my-4 fs-5 text-black`}
                >
                  Simply put, TutorFi blockchain technology can transform any
                  company who has the guts to name their training programs
                  around any result that matters. As of today, we are the only
                  Blockchain with real accountability built in and we are darn
                  proud to be leaders in this exploding Blockchain industry.
                </p>
              </div>
            </div>
            <div
              style={{
                background: "rgb(255, 255, 255)",
                marginBottom: "95px",
                padding: "5vw  0 0 0 ",
              }}
              className="text-center"
              id="features"
              ref={(element) => {
                ServicesRef.current["features"] = element;
              }}
            >
              <h1 style={{ fontSize: "26px" }} className="text-center">
                TutorFi Blockchain Technology Features
              </h1>
              <p className="pt-5 text-center">
                Below are some key features of this unique blockchain technology
                that took over 12 years to develop:
              </p>

              <img
                src={programmable}
                alt=""
                className="wow pulse"
                data-wow-iteration="5"
                style={{ maxHeight: "60vh", maxWidth: "100%" }}
              />
            </div>
            <div
              style={{
                background:
                  "linear-gradient(to bottom,rgb(255, 255, 255) 50%, var(--bs-body-bg) 50%",
              }}
            >
              <div
                className="container mt-n10"
                style={{ maxWidth: width > 768 ? "86%" : "98%" }}
              >
                <div className="row">
                  <div className="col-xxl-12 col-xl-12 mb-4">
                    <div className="card h-100">
                      <div className="card-body d-flex flex-column justify-content-center py-5 py-xl-4">
                        <div className="row align-items-center">
                          {infoCards.map((item, infoCardsIndex) => {
                            return (
                              <div
                                className={`col-sm-6`}
                                key={infoCardsIndex + Math.random()}
                              >
                                <div className="card shadow-none border-0">
                                  <div className="card-body">
                                    <h5 className="card-title d-flex align-items-center">
                                      <div
                                        className="wow zoomIn  pe-3"
                                        data-wow-duration="1s"
                                        data-wow-offset="100"
                                      >
                                        <div
                                          className="wow tada"
                                          data-wow-delay="1s"
                                          data-wow-duration="1s"
                                        >
                                          <div
                                            className="wow rubberBand"
                                            data-wow-delay="2000ms"
                                            data-wow-duration="1s"
                                          >
                                            <div
                                              className="wow pulse animated"
                                              data-wow-delay="300ms"
                                              data-wow-iteration="infinite"
                                              data-wow-duration="2s"
                                            >
                                              <img
                                                className="img-fluid"
                                                src={item.image}
                                                style={{
                                                  maxWidth: "60px",
                                                  width: 60,
                                                }}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {item.title}
                                    </h5>
                                    <p className="card-text">
                                      {item.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <div className="col-sm-12">
                            <div className="card shadow-none border-0">
                              <div className="card-body">
                                <h5 className="card-title">
                                  Some Additional Features:
                                </h5>
                                <ul className="card-text">
                                  <li>
                                    TutorFi blockchain provide access to
                                    different types of users and each of users
                                    can check and validate the operations
                                    performed by other users.
                                  </li>
                                  <li>
                                    Easy to use blockchain with user friendly
                                    graphical user interface.
                                  </li>
                                  <li>
                                    Web based application and can be accessible
                                    from anywhere 24/7 with browser from any
                                    platform OS.
                                  </li>
                                  <li>
                                    Microsoft .NET Technology used in building
                                    and hosting these blockchains.
                                  </li>
                                  <li>
                                    Safe and secure i.e. username/password
                                    protected blockchains.
                                  </li>
                                  <li>
                                    Being maintained and upgraded more
                                    frequently to enhance user experience.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <GetStarted ref={ServicesRef} />
          </main>
          <Footer scrollToView={scrollToView} />
        </div>
      </div>
    </>
  );
}

export default App;
