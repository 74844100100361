import useWindowDimensions from "../utils/useWindowDimensions";
import one from "../assets/images/one.svg";
import two from "../assets/images/two.svg";
import getStarted from "../assets/images/getStarted.png";
import { forwardRef } from "react";

const GetStartedView = (props, ref) => {
  const { width } = useWindowDimensions();
  return (
    <div
      style={{ background: "var(--bs-body-bg)" }}
      id="getStarted"
      {...props}
      ref={(element) => {
        ref.current["getStarted"] = element;
      }}
    >
      <div
        className="row mx-0 justify-content-center"
        style={{ padding: "6vw 0" }}
      >
        <div
          className={`col-md-5 order-1 order-md-0 order-xs-1 align-self-center p-4 p-sm-5 p-md-5`}
        >
          <h1>Get started</h1>
          <p className="my-4">
            TutorFi.com is your portal into the world of TutorFi possibilities.
            The tech is new and ever-evolving. Lucky for you, we have two
            wonderful options on how to get started.
          </p>
        </div>
        <div className={`col-md-6 col-9 text-center`}>
          <img src={getStarted} alt="" className="w-75" />
        </div>
        <div className={`col-md-12 col-12 text-center`}>
          <div
            className="container mt-5 p-0 p-sm-3 p-md-4"
            style={{ maxWidth: width > 768 ? "92%" : "98%" }}
          >
            <div className="row">
              <div className="col-xxl-12 col-xl-12 mb-4">
                <div className="card h-100">
                  <div className="card-body d-flex flex-column justify-content-center py-5 py-xl-4">
                    <div className="row align-items-center">
                      <div className={`col-sm-6`}>
                        <div className="card shadow-none border-0">
                          <div className="card-body  text-start px-0 px-sm-3 px-md-4">
                            <h5 className="card-title d-flex align-items-center">
                              <div
                                className="wow zoomIn  pe-3"
                                data-wow-duration="1s"
                                data-wow-offset="100"
                              >
                                <div
                                  className="wow tada"
                                  data-wow-delay="1s"
                                  data-wow-duration="1s"
                                >
                                  <div
                                    className="wow rubberBand"
                                    data-wow-delay="2000ms"
                                    data-wow-duration="1s"
                                  >
                                    <div
                                      className="wow pulse animated"
                                      data-wow-delay="300ms"
                                      data-wow-iteration="infinite"
                                      data-wow-duration="2s"
                                    >
                                      <img src={one} alt="" /> Option
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </h5>
                            <p className="card-text text-start">
                              Create your own blockchain legacy program with the
                              help of our launch program that can help you
                              launch any onboarding or operational system.
                              Because this process requires a consultant, there
                              is a cost associated. To learn more
                            </p>
                            <a
                              href="https://etcsoftwaresolutions.com/"
                              className="btn btn-outline-secondary"
                            >
                              Click here
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className={`col-sm-6`}>
                        <div className="card shadow-none border-0">
                          <div className="card-body text-start px-0 px-sm-3 px-md-4">
                            <h5 className="card-title d-flex align-items-center">
                              <div
                                className="wow zoomIn  pe-3"
                                data-wow-duration="1s"
                                data-wow-offset="100"
                              >
                                <div
                                  className="wow tada"
                                  data-wow-delay="1s"
                                  data-wow-duration="1s"
                                >
                                  <div
                                    className="wow rubberBand"
                                    data-wow-delay="2000ms"
                                    data-wow-duration="1s"
                                  >
                                    <div
                                      className="wow pulse animated"
                                      data-wow-delay="300ms"
                                      data-wow-iteration="infinite"
                                      data-wow-duration="2s"
                                    >
                                      <img src={two} alt="" /> Option
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </h5>
                            <p className="card-text text-start">
                              Partner with TutorFi and create your own branded
                              solutions that you can offer other companies as
                              TutorFi proudly supports your dream to help others
                              create onboarding and operations systems in their
                              businesses. This option is only for consultants.
                              To learn more
                            </p>
                            <a
                              href="mailto:scott@tutorfi.com"
                              className="btn btn-outline-secondary"
                            >
                              Click here
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const GetStarted = forwardRef(GetStartedView);
export default GetStarted;
