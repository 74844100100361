import { useState } from "react";
import logo from "../assets/images/logo.png";
export default function TopNavigationBar({ scrollToView }) {
  const [menu] = useState([
    {
      menuTitle: "Get Started",
      slug: "getStarted",
      subMenu: [],
    },
    {
      menuTitle: "Features",
      slug: "features",
      subMenu: [],
    },
    {
      menuTitle: "Why It Works",
      slug: "whyItWorks",
      subMenu: [],
    },
  ]);

  return (
    <nav
      className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light pt-0 pb-1"
      id="topNavigationBar"
      style={{ flexDirection: "column" }}
    >
      <a
        className="navbar-brand pe-3 ps-4 ps-lg-4 pe-lg-0 text-center"
        href="index.html"
      >
        <img src={logo} alt="Logo" className="logoImage" />
      </a>
      <div
        className="mt-3"
        style={{
          background: "gold",
          padding: "6px 25px 4px",
          borderRadius: "30px",
        }}
      >
        <ul className="navbar-nav align-items-center me-auto">
          {menu.map((menuItem, menuIndex) => {
            return (
              <li
                className={`nav-item dropdown no-caret ${
                  menuIndex < menu.length - 1 ? "me-4 " : null
                } `}
                key={menuIndex + Math.random()}
              >
                <div
                  className="nav-link dropdown-toggle p-0"
                  id={`navbarDropdown-${menuIndex}`}
                  onClick={() => scrollToView(menuItem.slug)}
                >
                  <div className="fw-500 fs-7">{menuItem.menuTitle}</div>
                  {menuItem.subMenu.length > 0 ? (
                    <i className="fas fa-chevron-right dropdown-arrow"></i>
                  ) : null}
                </div>

                {menuItem.subMenu.length > 0 ? (
                  <div
                    className="dropdown-menu dropdown-menu-end py-0 me-sm-n15 me-lg-0 animated--fade-in-up"
                    aria-labelledby="navbarDropdownDocs"
                    style={{
                      maxWidth: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      maxHeight: "55vh",
                    }}
                  >
                    {menuItem.subMenu.map((subMenuItem, submenuIndex) => {
                      return (
                        <span key={Math.random()}>
                          <a
                            className="dropdown-item py-3"
                            href="http://tutorfi.codeinventiv.com/"
                            target="_blank"
                            rel="noreferrer"
                            key={submenuIndex + Math.random()}
                          >
                            <div className="text-gray-800">
                              {subMenuItem.menuTitle}
                            </div>
                          </a>
                          <div className="dropdown-divider m-0"></div>
                        </span>
                      );
                    })}
                  </div>
                ) : null}
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
}
